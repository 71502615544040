import React from 'react';

import { AreaServicos } from './styled';
import CardsServicos from './CardsServicos/index'

const Servicos = () => {
    return (
        <AreaServicos>
        <div class="container_servicos" id="servicos">
            <div class="titulo_servicos">
                <h4>Alguns dos nossos serviços</h4>
            </div>
            
            <CardsServicos />
            
        </div>
    </AreaServicos>
    );
}

export default Servicos;




