import React from 'react';
import { AreaHeader } from './styled';
import NavBar from './Nav/NavBar'

function Header ({acao}) {
    return (
        <AreaHeader>
            <div class="container_header_banner">
                <header className={ acao ? 'ativaCor' : '' }>
                        <h1 className="logo-header">
                            <a href="/">
                                <img src="imagens/logo.webp" alt="logo_Vica_Estratégias_Digitais" />
                            </a>
                        </h1>
                        <NavBar />
                </header>
                
                <main>
                    <div class="main_textos">
                        <h2 class="titulo_banner_1">ATAQUE COM ESTRATÉGIA.<br/> CONQUISTE COM RESULTADOS.</h2>
                        <h3 class="subtitulo_banner_2">somos uma agência de estratégias digitais</h3>
                        <img src="/imagens/imagem_banner_principal.webp" class="imagem_banner_principal" alt="triangulos" />
                    </div>
                </main>
                <div class="botao_flutuante_whatsapp">
                    <a href="https://wa.me/5551981090409"><img src="imagens/whatsapp_flutuante.webp" alt="botão whatsapp" /></a>
                </div>
            </div>
        </AreaHeader>
    );
}

export default Header;