import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay} from 'swiper';
import 'swiper/css';
import './styled.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class CardsServicos extends React.Component {
    render() {
        return(
            <div className="container_servicos_carrossel">
                <Swiper 
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={30}
                    rewind={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                      }}
                    navigation={true}
                    loop={true}
                    pagination={{clickable:true}}
                    breakpoints={{
                        280: {
                            slidesPerView: 1.2,
                        },
                        300:  {
                            slidesPerView: 1.5,
                        },
                        900: {
                            slidesPerView: 3.8,
                        },
                        1200: {
                            slidesPerView: 3.8,
                        }}
                    }
                    className="swiper-container-servicos"
                >   
                    <SwiperSlide className="slide-item-servicos">
                        <img src='imagens/servico_google_ads.webp' className='imagem_servicos' alt='Tráfego Pago no Google Ads' />
                        <p class="nome_servico"><strong>Tráfego Pago no Google Ads</strong></p>
                        <p className='desc_servico'>Anúncios de Rede de Pesquisa, Remarketing, Display, Youtube.</p>
                        <a href="https://wa.me/5551981090409">
                            <button className='cta_saiba_mais_servicos'><strong>Entre em contato</strong></button>
                        </a>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-servicos">
                        <img src='imagens/servico_redes_sociais_ads.webp' className='imagem_servicos' alt='Tráfego Pago no Google Ads' />
                        <p class="nome_servico"><strong>Tráfego Pago nas Redes</strong></p>
                        <p className='desc_servico'>Anúncios Facebook Ads, Instagram, Tiktok e Linkedin.</p>
                        <a href="https://wa.me/5551981090409">
                            <button className='cta_saiba_mais_servicos'><strong>Entre em contato</strong></button>
                        </a>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-servicos">
                        <img src='imagens/servico_crm.webp' className='imagem_servicos' alt='Tráfego Pago no Google Ads' />
                        <p class="nome_servico"><strong>Automações de Relacionamento</strong></p>
                        <p className='desc_servico'>Ferramenta de CRM, Dispatos de e-mail mkt e WhatsApp (Bot)</p>
                        <a href="https://wa.me/5551981090409">
                            <button className='cta_saiba_mais_servicos'><strong>Entre em contato</strong></button>
                        </a>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-servicos">
                        <img src='imagens/servico_social_media.webp' className='imagem_servicos' alt='Tráfego Pago no Google Ads' />
                        <p class="nome_servico"><strong>Social Media</strong></p>
                        <p className='desc_servico'>Criação de conteúdo para Redes Sociais, para foco em engajamento.</p>
                        <a href="https://wa.me/5551981090409">
                            <button className='cta_saiba_mais_servicos'><strong>Entre em contato</strong></button>
                        </a>
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-servicos">
                        <img src='imagens/servico_desenvolvimento_web.webp' className='imagem_servicos' alt='Tráfego Pago no Google Ads' />
                        <p class="nome_servico"><strong>Desenvolvimento de Sites</strong></p>
                        <p className='desc_servico'>Criação de UI/UX e codifição de site responsivo, otimizado para conversões.</p>
                        <a href="https://wa.me/5551981090409">
                            <button className='cta_saiba_mais_servicos'><strong>Entre em contato</strong></button>
                        </a>
                    </SwiperSlide>

                </Swiper>   
            </div>
            );
        }
    }