import React from 'react';
import { AreaClientes } from './styled';

const Clientes = () => {
    return (
        <AreaClientes>
        <div class="container_clientes" id="clientes">
            <div class="titulo_secao_cliente">
                <h2 class="titulo_2">NOSSA RAZÃO. NOSSOS CLIENTES</h2>
            </div>
            <ul class="lista_clientes">
                <li class="cliente">
                    <img src="imagens/cliente_1.webp" class="logo_cliente" alt="logo_cliente_1" />
                </li>
                <li class="cliente">
                    <img src="./imagens/cliente_2.webp" class="logo_cliente" alt="logo_cliente_2" />
                </li>
                <li class="cliente">
                    <img src="imagens/cliente_3.webp" class="logo_cliente" alt="logo_cliente_3" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_4.webp" class="logo_cliente" alt="logo_cliente_4" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_5.webp" class="logo_cliente" alt="logo_cliente_5" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_6.webp" class="logo_cliente" alt="logo_cliente_6" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_7.webp" class="logo_cliente" alt="logo_cliente_7" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_8.webp" class="logo_cliente" alt="logo_cliente_8" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_9.webp" class="logo_cliente" alt="logo_cliente_9" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_10.webp" class="logo_cliente" alt="logo_cliente_10" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_11.webp" class="logo_cliente" alt="logo_cliente_11" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_12.webp" class="logo_cliente" alt="logo_cliente_12" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_13.webp" class="logo_cliente" alt="logo_cliente_13" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_14.webp" class="logo_cliente" alt="logo_cliente_14" />
                </li>
                <li class="cliente">
                    <img src="/imagens/cliente_15.webp" class="logo_cliente" alt="logo_cliente_15" />
                </li>
            </ul>
        </div>
        </AreaClientes>        
    );
}

export default Clientes;