import styled from 'styled-components';

export const AreaSobre = styled.div`
    background-color: #f4f4f4;

    .sobre {
        width: 100%;
        padding: 100px 10%;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
    }

            .titulo_sobre_2 {
                font-size: 45px;
                font-weight: 800;
                text-align: right;
                text-transform: uppercase;
                color: #5C5C58;
                opacity: 10;
                margin-bottom: 30px;
            }

        .container_conteudo_sobre {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

            .carrossel_portfolio {
                width: 50%;
            }

            .box_texto_sobre {
                padding-top: 60px;
                font-size: 22px;
                font-weight: 600;
                width: 40%;
                text-align: right;
                line-height: 1.2;
            }
  
                .paragrafo_sobre_1 {
                    padding-bottom: 20px;
                    color: #5C5C58;
                }
  
                .paragrafo_sobre_2 {
                    color: #F9CA0E;
                    font-weight: 800;
                }

@media (max-width: 600px) {
        .sobre {
            width: 100%;
            padding: 0 7%;
        }

            .titulo_sobre_2 {
                font-size: 28px;
                font-weight: 800;
                text-align: right;
                text-transform: uppercase;
                color: #5C5C58;
                opacity: 10;
                margin-bottom: 30px;
            }

            .container_conteudo_sobre {
                justify-content: center;
                flex-direction: column;
                alig-item: center;
            }

                .carrossel_portfolio {
                    width: 100%;
                }

                .box_texto_sobre {
                    padding-top: 10px;
                    padding-bottom: 60px;
                    font-size: 20px;
                    width: 100%;
                    line-height: 1.1;
                }
`;