import styled from 'styled-components';

export const AreaFooter = styled.div`
    background-color: #5C5C58;
    color: #f4f4f4;

    .colunas_footer {
        padding: 40px 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

        .logo_footer {
            width: 200px;
        }

        .titulo_contato_footer, .titulo_horario_footer, .titulo_redes_footer, .titulo_endereco_footer {
            font-weight: 900;
            margin-bottom: 5px;
            color: #f9ca0e;
            text-transform: uppercase;
        }

        .contato_footer {
            margin-bottom: 40px;
        }
        
        .horario_atendimento_footer {
            margin-bottom: 40px;
        }

        .politicas_privacidade {
            cursor: pointer;
        }

            .politicas_privacidade:hover {
                font-weight: 700;
            }

        .container_redes_sociais {
            display: flex;
        }

            .icone_instagram_footer {
                padding-top: 10px;
                padding-bottom: 30px;
                margin-right: 15px;
                width: 40px;
            }

            .icone_facebook_footer {
                padding-top: 10px;
                padding-bottom: 30px;
                width: 40px;
            }

    .direitos_reservados {
        background-color: #f4f4f4;
        font-weight: 700;
        color: #5C5C58;
        text-align: center;
        padding: 20px;
        font-size: 14px;
        width: 100%;
    }

@media (min-width: 2100px) {
    .colunas_footer {
        padding: 40px 15%;
    }
}

@media (max-width: 600px) {
    .colunas_footer {
        padding: 0 5%;
        flex-direction: column;
        justify-content: none;
    }

        .logo_footer {
            width: 150px;
        }

        .titulo_contato_footer, .titulo_horario_footer, .titulo_redes_footer, .titulo_endereco_footer {
            margin-bottom: 2px;
        }

        .politicas_privacidade {
            margin-bottom: 40px;
        }

        .texto-endereco {
            margin-bottom: 40px;
        }
}

@media (max-width: 280px) {
        .logo_footer {
            width: 120px;
        }

        .titulo_contato_footer, .titulo_horario_footer, .titulo_redes_footer, .titulo_endereco_footer {
            margin-bottom: 2px;
        }

        .texto-endereco {
            margin-bottom: 40px;
        }
}
`;