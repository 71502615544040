import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay} from 'swiper';
import 'swiper/css';
import './styled.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


export default class Carrossel extends React.Component {
    render() {
        return(
            <div className="container_equipe">
                <Swiper 
                    modules={[Navigation, Pagination, Autoplay]}
                    autoplay={{delay:3000 }}
                    navigation={true}
                    pagination={{clickable:true}}
                    className="swiper_container_equipe"
                >   
                    <SwiperSlide className="slide_item_equipe">
                        <img src={require('../../equipe_vini.webp')} className="imagem_equipe" alt="secao_equipe" />
                        <div class="textos-slide">
                            <h4 class="nome_colaborador">@VINI_CEMIN</h4>
                            <p class="funcao">CO-CEO</p>
                            <p class="sobre_funcao">GRADUADO EM MARKETING E ATUANDO COM FOCO NO MERCADO DIGITAL HÁ 5 ANOS, VINÍCIOS É NOSSO FUNDADOR.
                                COM VASTA EXPERIÊNCIA COMO GROwTH HACKER, POSSUI BOAS HABILIDADES DE LEITURA DE RESULTADOS, 
                                PLANEJAMENTO E ALTO DESEMPENHO EM CAMPANHAS COM FOCO EM VENDAS.
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="slide_item_equipe">
                        <img src={require('../../equipe_carol.webp')} className="imagem_equipe" alt="secao_equipe" />
                        <div class="textos-slide">
                            <h4 class="nome_colaborador">@carolinaguillamelau</h4>
                            <p class="funcao">CO-CEO</p>
                            <p class="sobre_funcao">GRADUADA EM PUBLICIDADE E PROPAGANDA E ATUANDO COM MÍDIAS ON E OFFLINE HÁ 10 ANOS, 
                                CAROL SE DESTACA PELO CONHECIMENTO AMPLO EM DIVERSOS RAMOS DE ATUAÇÃO DENTRO DA COMUNICAÇÃO, 
                                TENDO ATUADO EM DIVERSAS ÁREAS QUE VÃO DESDE A CONcEPÇÃO DE CAMPANHAS E CONTEÚDOS, ATÉ A GERÊNCIA 
                                DE EQUIPES.
                            </p>
                        </div>
                    </SwiperSlide>
                </Swiper>   
            </div>
            );
        }
    }