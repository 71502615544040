import React from 'react';

import { AreaFooter } from './styled';

const Footer = () => {
    return (
        <AreaFooter>
            <div className='container_footer'>
                <div className='colunas_footer'>
                    
                    <div className='coluna_footer_1'>
                        <img src="imagens/logo.webp" className='logo_footer' alt="" />
                    </div>

                    <div className='coluna_footer_2'>
                        <ul className='contato_footer'>
                            <p className='titulo_contato_footer'>Contato</p>
                            <br/>
                            <li>
                                <a href='tel:+5551981090409'>
                                    <p className='dado_contato'>51 98109.0409</p>
                                </a>
                            </li>
                            <br/>
                            <li>
                                <a href='tel:+5551984155327'>
                                    <p className='dado_contato'>51 98415.5327</p>
                                </a>
                            </li>
                            <li>
                                <img src="" className='icone_contato' alt="" />
                                <a href='mailto:vini@vicaagencia.com.br'>
                                    <p className='dado_contato'>vini@vicaagencia.com.br</p>
                                </a>
                            </li>
                            <li>
                                <img src="" className='icone_contato' alt="" />
                                <a href='mailto:carol@vicaagencia.com.br'>
                                    <p className='dado_contato'>carol@vicaagencia.com.br</p>
                                </a>
                            </li>
                        </ul>
                        <ul className='horario_footer'>
                            <p className='titulo_horario_footer'>Horário de funcionamento:</p>
                            <li className='horario_atendimento_footer'>
                            09:00 - 19:00
                            </li>
                        </ul>
                        <a href='/politicas-privacidade'>
                            <p className='politicas_privacidade'>Politicas de Privacidade</p>
                        </a>
                    </div>

                    <div className='coluna_footer_3'>
                        <ul className='redes_footer'>
                            <p className='titulo_redes_footer'>Nossas Redes</p>
                            <li className='container_redes_sociais'>
                                <a href='https://www.instagram.com/vicaagenciadigital/'>
                                    <img src="imagens/icone_instagram.webp" className='icone_instagram_footer' alt="" />
                                </a>
                                <a href='https://www.facebook.com/vicaagenciadigital'>
                                    <img src="imagens/icone_facebook.webp" className='icone_facebook_footer' alt="" />
                                </a>
                            </li>
                        </ul>
                    </div>

                </div>
                <p className='direitos_reservados'>
                    Todos os direitos reservados | Desenvolvido por Vica Estratégias Digitais.
                </p>
            </div>
        </AreaFooter>
    );
}

export default Footer;