import React,{useState} from "react";
import "./styled.css"

function NavBar() {
    const [click, setClick] = useState(false);

    const handleClick = () =>setClick(!click);
    return (
        <>
        <nav id="menu">
            <ul className={click ? "itens-menu active" : "itens-menu"}>
                <li className="menu" onClick={handleClick}>
                    <a href="#servicos">Servicos</a>
                </li>
                <li className="menu" onClick={handleClick}>
                    <a href="#sobre">Sobre</a>
                </li>
                <li className="menu" onClick={handleClick}>
                    <a href="#clientes">Clientes</a>
                </li >
            </ul>
        </nav>
        <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        </>
    )
}

export default NavBar;
 