import styled from 'styled-components';

export const AreaClientes = styled.div`
    background-color: white;

        .container_clientes {
            width: 100%;
            padding: 30px 10%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-bottom: 100px;
        }

            .titulo_secao_cliente{
                margin-top: 90px;
                display: flex;
                flex-direction: column;
                font-weight: 800;
                text-align: center;
                margin-bottom: 30px;
                color: #5C5C58;
            }

                .titulo_2 {
                    font-size: 60px;
                    text-transform: uppercase;
                }

            .lista_clientes {
                display: flex;
                flex-wrap: wrap;
                font-weight: 800;
                justify-content: center;
            }

                .cliente {
                    background-color: #f4f4f4;
                    width: 180px;
                    height: 180px;
                    border-radius: 5px;
                    margin-left: 20px;
                    margin-bottom: 20px;
                    text-align: center;
                    box-shadow: 5px 10px 10px 1px #d3d3d3;
                    padding-left: 5px;
                    padding-right: 5px;
                    cursor: pointer;
                }

                    .cliente:hover {
                        position: relative;
                        top: -5px;
                        box-shadow: 5px 10px 10px 1px gray;
                    }
  
                    .logo_cliente {
                        width: 80%;
                        padding-top: 20px;
                    }


@media (max-width: 600px) {
    .container_clientes {
        width: 100%;
        padding: 0 5%;
    }

        .titulo_secao_cliente{
            margin-top: 90px;
        }

            .titulo_2 {
                font-size: 30px;
            }

        .lista_clientes {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 60px;
        }

            .cliente {
                width: 140px;
                height: 140px;
                margin-left: 15px;
                margin-bottom: 15px;
            }

                .cliente:hover {
                    position: relative;
                    top: -5px;
                    box-shadow: 5px 10px 10px 1px gray;
                }

                .logo_cliente {
                    width: 80%;
                    padding-top: 20px;
                }
}              
`;