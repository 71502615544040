import React, { useEffect, useState } from 'react';

import './App.css';
import Header from './Header';
import Sobre from './Sobre';
import Equipe from './Equipe';
import Clientes from './Clientes';
import Servicos from './Servicos';
import Footer from './Footer';
import Form from './Form';

function App() {

  const [ativaCor, setAtivaCor] = useState(false);

  useEffect(function(){
    function posicaoScroll(){
      if(window.scrollY > 1){
        setAtivaCor(true);
      } else {
        setAtivaCor(false);
      }
    }

    window.addEventListener('scroll', posicaoScroll);
  }, []);

  return (
    <>
        <Header acao={ativaCor} />
        <Sobre />
        <Servicos />
        <Clientes />
        <Equipe />
        <Form />
        <Footer />
    </>
  );
}

export default App;
