import styled from 'styled-components';

export const AreaHeader = styled.div`
  height: 900px;  
  background: url(imagens/background_secao1.webp);

    strong {
      font-weight: 700;
    }

    header {
      height: 160px;
      width: 100%;
      padding: 0 10%;
      padding-top: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      z-index: 99;
      transition: all ease 0.3s;
      }

      .logo-header {
        flex-grow: 0;
  
          img {
            width: 150px;
            cursor: pointer;
          }
        }

        .ativaCor {
          background-color: #5C5C58;
          height: 40px;
          padding-top: 40px;
          padding-bottom: 40px;

            .logo-header {
              img {
              width: 100px;
              }
            }
          }
  

    main {
      width: 100%;
      padding: 20px 10%;
    }

      .main_textos {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: right;

        .titulo_banner_1 {
          color: white;
          font-size: 60px;
          font-weight: 800;
          margin-bottom: 40px;
          margin-top: 300px;
        }

        .subtitulo_banner_2 {
          text-transform: uppercase;
          opacity: 30%;
          font-weight: 600;
          font-size: 45px;
          text-shadow: 1px -5px 3px black;
        }
      }

      .imagem_banner_principal {
        width: 200px;
        position: relative;
        margin-top: 30px;
        left: -230px;
        }

  .botao_flutuante_whatsapp img {
    width: 80px;
    position: fixed;
    display: flex;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 99;
    box-shadow: 2px 2px 5px black;
    border-radius: 100%;
    }

      .botao_flutuante_whatsapp:hover {
        bottom: 32px;
        right: 32px;
      }
    
@media (max-width: 600px) {
  height: 800px;
  background: url(imagens/background_secao1.webp) no-repeat bottom center;
    
    .header {
      height: 30px;
      padding: 0 7%;
    }

      .ativaCor {
        height: 20px;

        img {
          width: 30px;
        }

        .nav-icon {
          top: 10px;
      }
    }

      .logo-header img {
        width: 150px;
       }

      main {
        padding: 10px 7%;
      }
    
      .main_textos {

        .titulo_banner_1 {
          font-size: 36px;
          margin-bottom: 40px;
          margin-top: 200px;
          line-height: 1.2;
        }

        .subtitulo_banner_2 {
          opacity: 100%
          color: #98C56F;
          font-weight: 800;
          font-size: 30px;
          text-shadow: none;
        }
      }
    
      .imagem_banner_principal {
        width: 200px;
        left: -100px;
        margin-top: none;
        }
}
`;