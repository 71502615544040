import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectCards} from 'swiper';
import 'swiper/css';
import './styled.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';

export default class Portfolio extends React.Component {
    render() {
        return(
            <div className="container_portfolio">
                <Swiper 
                    effect={'cards'}
                    grabCursor={true}
                    modules={[Navigation, Pagination, Autoplay, EffectCards]}
                    autoplay={{delay:2000 }}
                    navigation={true}
                    loop={true}
                    pagination={{clickable:true}}
                    className="swiper-container-portfolio"
                >   
                    <SwiperSlide className="slide-item-portfolio">
                        <img 
                            src="imagens/cliente_vica_buonna_pizza.webp" 
                            className='imagem_portfolio' 
                            alt="Portifólio Vica, cliente Buonna Pizza Markel" 
                        />
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-portfolio">
                        <img 
                            src="imagens/cliente_vica_multitec.webp"
                            className='imagem_portfolio'
                            alt="Portifólio Vica, cliente Multitec Agro"
                        />
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-portfolio">
                        <img 
                            src="imagens/cliente_vica_dra_alessandra_vieira.webp" 
                            className='imagem_portfolio' 
                            alt="Portifólio Vica, cliente Dra. Alessadra Vieira" 
                        />
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-portfolio">
                        <img 
                            src="imagens/cliente_vica_dedetizadora_antipragas.webp"
                            className='imagem_portfolio'
                            alt="Portifólio Vica, cliente Dedetizadora Antipragas"
                        />
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-portfolio">
                        <img 
                            src="imagens/cliente_vica_anja_vinhos.webp"
                            className='imagem_portfolio'
                            alt="Portifólio Vica, cliente Anja Vinhos"
                        />
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-portfolio">
                        <img 
                            src="imagens/cliente_vica_trianimal.webp"
                            className='imagem_portfolio'
                            alt="Portifólio Vica, cliente TriAnimal"
                        />
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-portfolio">
                        <img 
                            src="imagens/cliente_vica_vica.webp"
                            className='imagem_portfolio'
                            alt="Portifólio Vica, cliente Vica"
                        />
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-portfolio">
                        <img 
                            src="imagens/cliente_vica_acai_na_mao.webp"
                            className='imagem_portfolio'
                            alt="Portifólio Vica, cliente Açaí na Mão"
                        />
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-portfolio">
                        <img 
                            src="imagens/cliente_vica_mais_assertiva_imob.webp"
                            className='imagem_portfolio'
                            alt="Portifólio Vica, cliente Mais Assertiva Imobiliária"
                        />
                    </SwiperSlide>

                    <SwiperSlide className="slide-item-portfolio">
                        <img 
                            src="imagens/cliente_vica_mais_assertiva_odonto.webp"
                            className='imagem_portfolio'
                            alt="Portifólio Vica, cliente Mais Assertiva Odontologia"
                        />
                    </SwiperSlide>

                </Swiper>
                <button className='baixar_portfolio'>
                    <p><a href='/portfolio'>Baixar portfólio</a></p>
                </button>   
            </div>
            );
        }
    }