import styled from 'styled-components';

export const AreaServicos = styled.div`
    background-color: #5C5C58;

    .container_servicos {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

        .titulo_servicos {
            padding: 100px 10%;
            font-size: 60px;
            text-transform: uppercase;
            opacity: 50%;
            padding-bottom: 30px;
            color: white;
            font-weight: 800;
            text-align: center;
        }

@media (max-width: 600px) {
            .titulo_servicos {
                padding: 90px 7%;
                font-size: 32px;
                padding-bottom: 20px;
            }
}
`;