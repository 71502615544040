import React from 'react';

import { AreaSobre } from './styled';
import Portfolio from './Portfolio/index';

const Sobre = () => {
    return (
        <AreaSobre>
            <div className='sobre' id="sobre">
                
                <div className='container_conteudo_sobre'>
                    <div className='carrossel_portfolio'>
                        <Portfolio />
                    </div>
                    <div className='box_texto_sobre'>
                        <div className='titulo_sobre'>
                            <h4 className='titulo_sobre_2'>foco no resultado</h4>
                        </div>
                        <p className='paragrafo_sobre_1'>SOMOS A SEDE DE ENTREGAR RESULTADOS, DE ESTARMOS PRÓXIMOS E SENTIMOS AS 
                            DORES DOS NOSSOS CLIENTES.
                            <br/>
                            SOMOS COMPACTOS DE TAMANHO MAS GRANDES DE SONHOS. GRANDES EM ENTREGA. E GRANDES EM RESULTADOS.
                            <br/>
                            <br/>
                            SOMOS VICA.
                        </p>
                        <p className='paragrafo_sobre_2'>NASCEMOS NO INÍCIO DE 2022 DE UM SONHO DE ENTREGAR RESULTADOS REAIS PARA 
                            EMPRESAS, TRABALHANDO DE PERTO E ENTENDENDO AS REAIS DORES DE PESSOAS QUE QUEREM CRESCER VALORIAZANDO QUEM 
                            CAMINHA JUNTO NESSE OBJETIVO.
                        </p>
                    </div>
                </div>
            </div>
        </AreaSobre>
    );
}

export default Sobre;