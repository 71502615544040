
import React from 'react';

import Carrossel from './Carrossel';
import './styled.css';

const Equipe = () => {
    return (
        <div class="container_secao_equipe">
            <h4 class="titulo_secao_equipe">Time compacto. Dedicação ampla.</h4>
            <div class="carrossel_equipe">
                <Carrossel />
            </div>
        </div>
    );
}

export default Equipe;