import React from 'react';
import './styled.css';

const Form = () => {
    return (
        <div className='area_formulario' id='contato'>
            <iframe src="https://vicaagencia.com.br/form/" scrolling="no" frameborder="no"></iframe>
        </div>
    )
}

export default Form;